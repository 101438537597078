import { admin2 } from './instance'

export const GetIndoorControlList = async ({
  shopId,
  start,
  limit,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/indoorControlConfig`,
    params: {
      start,
      limit,
    },
  })
}

export const CreateIndoorControl = async ({
  shopId,
  vendor,
  goodLinkerSetting,
  BranchId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/indoorControlConfig`,
    data: {
      vendor,
      goodLinkerSetting,
      BranchId,
    },
  })
}

export const FindDevice = async ({
  shopId,
  BranchId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/indoorControlDevice/statusByBranch`,
    params: {
      BranchId,
    },
  })
}

export const CreateDevice = async ({
  shopId,
  name,
  type,
  config,
  BranchId,
  IndoorControlConfigId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/indoorControlDevice`,
    data: {
      name,
      type,
      config,
      BranchId,
      IndoorControlConfigId,
    },
  })
}

export const UpdateDevice = async ({
  shopId,
  id,
  status,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/indoorControlDevice/${id}/status`,
    data: {
      status,
    },
  })
}

export const ResetPassword = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/indoorControlDevice/${id}/resetPassword`,
  })
}
