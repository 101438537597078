<template>
  <div class="indoor-control-parameters">
    <PageTitle
      title="室內設備控制設定"
      icon="chevron_left"
      hideBtn
      style="margin-bottom: 64px"
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <div v-if="branchList.length" class="parameters-category-list">
      <CategoryBlock
        v-for="param in branchList"
        :key="param.name"
        :data="param"
      />
    </div>
    <div v-else class="empty-block">
      <img
        width="150"
        style="margin: auto"
        src="@/assets/icon/nodata/no_data.svg"
        alt=""
        srcset=""
      >
      <span class="content">目前尚未有完成串接的設備，<br>
        請您先前往「基本參數設定 > 門市設定」<br>
        新增門市，並聯繫客服。</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from 'vue'
import PageTitle from '@/components/Title/PageTitle'
import CategoryBlock from '../components/CategoryBlock.vue'
import { GetIndoorControlList } from '@/api/indoorControl'
import store from '@/store'
import { map, get } from 'lodash'

export default defineComponent({
  name: 'IndoorControlSettings',
  components: { PageTitle, CategoryBlock },
  setup (props) {
    const shopId = computed(() => store.getters.shop)
    const tableData = ref([])

    const getStore = async () => {
      const [res, err] = await GetIndoorControlList({
        shopId: shopId.value,
        start: 0,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      tableData.value = res
      console.log(tableData.value)
    }
    const branchList = computed(() => map(tableData.value, item => {
      return {
        path: `/parameter/indoor-control-detail-settings/${item.BranchId}/${get(item, 'Branch.name')}`,
        meta: {
          title: get(item, 'Branch.name'),
        },
      }
    }),
    )
    onMounted(async () => {
      await getStore()
    })
    return {
      tableData,
      branchList,
    }
  },
})
</script>

<style scoped lang="postcss">
.parameters-category-list {
  @apply grid grid-cols-3 gap-[44px];
}

@media screen and (max-width: 1200px) {
  .parameters-category-list {
    @apply grid grid-cols-2;
  }
}

.empty-block {
  @apply flex flex-col w-full justify-center items-center leading-[30px] p-[20px] text-[14px] mt-[160px];
}

.content {
  @apply text-center text-sub leading-normal tracking-[1px] text-gray-100 mt-[8px];
}
</style>
